/**
 * The `sanitizeAndParse` function attempts to safely parse a JSON string from a given response.
 * It works in the following steps:
 * 1. **Initial Parsing**: Tries to parse the input `response` as JSON directly.
 * 2. **Error Handling**: If parsing fails (e.g., the input is wrapped in an HTML-like tag),
 *    it removes the outer tag using a regex and retries parsing.
 *
 * Steps:
 * - The function first uses `JSON.parse` to attempt parsing.
 * - If an error occurs during parsing:
 *   - A regex is applied to remove any outer HTML-like tags from the string.
 *   - The sanitized string (inner content of the tag) is then parsed as JSON.
 *
 * @param {string} response - The input string, expected to be JSON or JSON wrapped in an HTML-like tag.
 * @returns {T} - The parsed JSON object or array, with type `T` inferred from usage.
 * @throws {SyntaxError} - If the sanitized response is still not valid JSON.
 */

export const sanitizeAndParse = <T = any>(response: string): T => {
  try {
    // Attempt to parse the response directly as JSON.
    return JSON.parse(response);
  } catch (error) {
    // Define a regex to extract the inner content of an HTML-like wrapper tag.
    const wrapperRegex = /^<([a-z][\da-z]*)\b[^>]*>(.*?)<\/\1>$/is;

    // Remove the outer wrapper and extract the inner content.
    const sanitizedResponse = response.trim().replace(wrapperRegex, '$2');

    // Retry parsing the sanitized response as JSON.
    return JSON.parse(sanitizedResponse);
  }
};
