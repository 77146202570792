import { Maybe } from "@vsf-enterprise/bloomreach-discovery-sdk/lib/types/schema";
import { sanitizeAndParse } from "mkm-avengers";
import { createFindCategoryQueryString } from "@/helpers/queries/findCategoryQueryBuilder";

type CategoryDescription = {
  desc: string;
  seo: string;
};

type CategoryState = {
  data: any;
  bigCommerceCategory: any;
  categoryDescription: Maybe<CategoryDescription>;
  loadingItems: boolean;
};

type QueryParams = {
  categoryID: string;
  itemsPerPage: number;
  offset: number;
  filterValues: any;
  sortTerm: any;
  onlyTotal: boolean;
};

type SearchParams = {
  query: string;
  filterValues: any;
  sortTerm: any;
  itemsPerPage: number;
  offset: number;
};

const queryHintCustomAttrFields = [
  "bcid",
  "brand",
  "url",
  "is_tally",
  "price",
  "price_inc_vat",
  "offer_price",
  "offer_price_inc_vat",
  "availability_flag",
  "availability_reason",
  "cf_width",
  "unit",
  "offer_unit",
  "cf_thickness",
  "cf_treatment",
  "cf_grade",
  "cf_icon-green",
  "cf_icon-energy",
  "cf_icon-materials",
  "cf_icon-planet",
];

const useCategory = () => {
  const state = useState<CategoryState>(`useCategory`, () => ({
    data: null,
    bigCommerceCategory: null,
    categoryDescription: null,
    loadingItems: false,
  }));

  const { selectedBranchId } = useBranches();

  const bloomreachCookie = useCookie("_br_uid_2");
  const viewId = computed(() => (selectedBranchId.value ? `b_${selectedBranchId.value}` : "default"));
  const brUid = computed(() => (bloomreachCookie ? bloomreachCookie.value : "noBrCookie"));
  const { BR_ENV_TYPE } = useRuntimeConfig().public;

  const buildQueryHint = (filterValues: any) => ({
    viewId: viewId.value,
    brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
    brUid2: brUid.value ?? undefined,
    facetFieldFilters: filterValues,
    customAttrFields: queryHintCustomAttrFields,
  });

  const fetchCategory = async ({
    categoryID,
    itemsPerPage,
    offset,
    filterValues,
    sortTerm,
    onlyTotal = false,
  }: QueryParams) => {
    state.value.loadingItems = true;

    const categoryQuery = createFindCategoryQueryString({
      categoryId: categoryID,
      offset,
      limit: itemsPerPage,
      sortFields: sortTerm,
      viewId: viewId.value,
      brEnvType: BR_ENV_TYPE === "STAGING" ? "STAGING" : undefined,
      brUid2: brUid.value ?? undefined,
      facetFieldFilters: filterValues,
    });

    const { data: categoryData, error } = await useTimedAsyncData("getCategoryBC", () =>
      useSdk().bcAPI.fetchFederatedCategory({ categoryId: Number(categoryID), query: categoryQuery }),
    );

    state.value.data = categoryData.value.brCategory ?? state.value.data;
    state.value.bigCommerceCategory = categoryData.value.category ?? state.value.data;
    state.value.categoryDescription = sanitizeAndParse<CategoryDescription>(categoryData.value?.category.description);

    useHandleError(error.value);

    state.value.loadingItems = false;

    return categoryData.value;
  };

  const search = async ({ query, filterValues, sortTerm, itemsPerPage, offset }: SearchParams) => {
    state.value.loadingItems = true;

    const { data, error } = await useAsyncData("findItemsByKeyword", () =>
      useSdk().brSearch.findItemsByKeyword({
        text: query,
        limit: itemsPerPage,
        offset: offset,
        sortFields: sortTerm,
        queryHint: buildQueryHint(filterValues),
        expect:
          "queryHint { cursor autoCorrectQuery autoCorrectQuerySet redirectHint { url } } total limit offset facetResult { fields { id name values { id parentId name count } } } items { slug itemId { id } displayName description purchasePrice { moneyAmounts { amount currency displayValue } } listPrice { moneyAmounts { amount currency displayValue } } imageSet { original { dimension { w h } link { href ref } } thumbnail { dimension { w h } link { href ref } } } salePriceRange priceRange customAttrs { name values } variants { itemId { id } displayName description master imageSet { original { dimension { w h } link { href ref } } thumbnail { dimension { w h } link { href ref } } } purchasePrice { moneyAmounts { amount currency displayValue } } listPrice { moneyAmounts { amount currency displayValue } } mainItem { itemId { id } displayName } customAttrs { name values } availability { inStock availableQuantity restockableInDays } } }",
      }),
    );

    state.value.data = data.value ?? state.value.data;

    state.value.loadingItems = false;
  };

  return {
    search,
    fetchCategory,
    ...toRefs(state.value),
  };
};

export default useCategory;
