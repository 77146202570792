type FacetFieldFilter = {
  id: string;
  values: string[];
};

export interface FindCategoryQueryOptions {
  categoryId: string;
  offset: number;
  limit: number;
  sortFields: string;
  viewId: string;
  brEnvType?: string;
  brUid2?: string;
  facetFieldFilters: FacetFieldFilter[];
}

/**
 * Creates a properly formatted GraphQL query string.
 * @param {FindCategoryQueryOptions} options - Configuration for the query.
 * @returns {string} - Fully-formed GraphQL query string.
 */
export function createFindCategoryQueryString(options: FindCategoryQueryOptions): string {
  const { categoryId, viewId, brEnvType, brUid2, offset, limit, sortFields, facetFieldFilters } = options;

  const customAttrFields: string[] = [
    "bcid",
    "brand",
    "url",
    "is_tally",
    "price",
    "price_inc_vat",
    "offer_price",
    "offer_price_inc_vat",
    "availability_flag",
    "availability_reason",
    "cf_width",
    "unit",
    "offer_unit",
    "cf_thickness",
    "cf_treatment",
    "cf_grade",
    "cf_icon-green",
    "cf_icon-energy",
    "cf_icon-materials",
    "cf_icon-planet",
  ];

  const brUid2Line = brUid2 ? `brUid2: "${brUid2}"` : "";

  const facetFieldFiltersString =
    facetFieldFilters.length > 0
      ? `facetFieldFilters: [${facetFieldFilters
          .map(({ id, values }) => `{ id: "${id}", values: [${values.map((value) => `"${value}"`).join(", ")}] }`)
          .join(", ")}]`
      : "";

  const queryHintString = `
    viewId: "${viewId}",
    ${brEnvType ? `brEnvType: "${brEnvType}",` : ""}
    ${brUid2Line ? `${brUid2Line},` : ""}
    customAttrFields: [${customAttrFields.map((field) => `"${field}"`).join(", ")}]
    ${facetFieldFiltersString ? `,${facetFieldFiltersString}` : ""}
  `.trim();

  return `#graphql
  query findItemsByCategory {
    findItemsByCategory(
      categoryId: "${categoryId}",
      limit: ${limit},
      offset: ${offset},
      sortFields: "${sortFields}",
      queryHint: {
        ${queryHintString}
      }
    ) {
      total
      limit
      offset
      facetResult {
        fields {
          id
          name
          values {
            id
            parentId
            name
            count
          }
        }
      }
      items {
        slug
        itemId {
          id
        }
        displayName
        description
        purchasePrice {
          moneyAmounts {
            amount
            currency
            displayValue
          }
        }
        listPrice {
          moneyAmounts {
            amount
            currency
            displayValue
          }
        }
        imageSet {
          original {
            dimension {
              w
              h
            }
            link {
              href
              ref
            }
          }
          thumbnail {
            dimension {
              w
              h
            }
            link {
              href
              ref
            }
          }
        }
        salePriceRange
        priceRange
        customAttrs {
          name
          values
        }
        variants {
          itemId {
            id
          }
          displayName
          description
          master
          imageSet {
            original {
              dimension {
                w
                h
              }
              link {
                href
                ref
              }
            }
            thumbnail {
              dimension {
                w
                h
              }
              link {
                href
                ref
              }
            }
          }
          purchasePrice {
            moneyAmounts {
              amount
              currency
              displayValue
            }
          }
          listPrice {
            moneyAmounts {
              amount
              currency
              displayValue
            }
          }
          mainItem {
            itemId {
              id
            }
            displayName
          }
          customAttrs {
            name
            values
          }
          availability {
            inStock
            availableQuantity
            restockableInDays
          }
        }
      }
    }
  }
  `;
}
