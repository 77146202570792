/* eslint-disable sonarjs/cognitive-complexity */
import { BreadcrumbItemProps } from "@/components/UI/Breadcrumbs/breadcrumbTypes";
import { checkIfFilterIsSelected, combineAllFacets } from "@/helpers/category";
import { capitalizeFirstLetter, capitalizeWords, findByUrl } from "mkm-avengers";
import { checkIfItsBrowserStepBack } from "./categoryPageHelpers";
import type { UseCategoryPageState } from "./categoryPageTypes";
import { onUnmounted } from "vue";

const useCategoryPage = () => {
  const state = useState<UseCategoryPageState>(`useCategoryPage`, () => ({
    isCatLanding: false,
    categoryName: "",
    currentPage: 1,
    itemsPerPage: 20,
    facets: [],
    filteredParams: {},
    currentCategoryBrId: "",
    loadingItems: false,
    currentSearchPage: 1,
  }));

  const { data: categoryBCData, fetchCategory, bigCommerceCategory, search } = useCategory();

  const { convertToBRData, filters } = useFilters();
  const { data: categoryTreeItems } = useCategoryTree();
  const { sortTerm, sortBy } = useSortBy();

  const route = useRoute();
  const router = useRouter();

  const currentPagePath = url(route.path);
  const categoryBC = findByUrl(categoryTreeItems.value ?? [], currentPagePath);
  const routeSlug = route.query.q === undefined ? "" : decodeURIComponent(route.query.q as string);

  const onSortBy = async (value: string, searchTemplate = false) => {
    sortBy(false, value);
    if (searchTemplate) {
      await search({
        query: routeSlug as string,
        filterValues: convertToBRData(),
        sortTerm: sortTerm.value,
        itemsPerPage: state.value.itemsPerPage,
        offset: (state.value.currentSearchPage - 1) * state.value.itemsPerPage,
      });
      return;
    }
    await fetchCategory({
      categoryID: state.value.currentCategoryBrId,
      itemsPerPage: state.value.itemsPerPage,
      offset: 0,
      onlyTotal: false,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
    });
  };

  const findById = (dataId: any) => {
    let result;

    const iter = (a: any) => {
      if (Number(a.id) === Number(dataId)) {
        result = a;
        return true;
      }
      return Array.isArray(a.children) && a.children.some((element: any) => iter(element));
    };

    categoryTreeItems?.value?.some((element: any) => iter(element));
    return result;
  };

  // @ts-ignore
  const checkIsValidFacetAndSetUrl = (dataBR: any) => {
    let categoryIndex;
    const updatedCategoryFacets: any[] = [];
    const modifiedFacets = [];
    const categoryFacets = dataBR.filter((el: { id: string }, index: any) => {
      if (el.id === "category") {
        categoryIndex = index;
        return true;
      } else {
        modifiedFacets.push(el);
      }

      return false;
    });

    if (categoryFacets.length > 0) {
      categoryFacets[0].values.forEach((el: { id: any }) => {
        const existingCategory = findById(el.id);
        // @ts-ignore
        if (existingCategory) updatedCategoryFacets.push({ ...el, url: existingCategory.url });
      });
    }

    // @ts-ignore
    const copyOfCategoryFacets = { ...dataBR[categoryIndex] };
    delete copyOfCategoryFacets.values;
    copyOfCategoryFacets.values = updatedCategoryFacets;

    modifiedFacets.push(copyOfCategoryFacets);

    return modifiedFacets;
  };

  const generateQueryParams = (page: any) => {
    state.value.filteredParams = checkIfFilterIsSelected(filters);

    const params: Record<string, string> = { ...state.value.filteredParams };

    if (page && page > 1) params.p = page.toString();
    if (sortTerm.value) params.sort_by = sortTerm.value;

    const sortValue = route.query.sort_value?.toString();
    if (sortValue) params.sort_value = sortValue;

    // Extract q first to ensure it's placed at the beginning
    const searchQuery = route.query.q?.toString();
    let queryString = searchQuery ? `q=${encodeURIComponent(searchQuery)}` : "";

    // Append other params
    const otherParams = new URLSearchParams(params).toString();
    if (otherParams) queryString += queryString ? `&${otherParams}` : otherParams;

    return queryString;
  };

  const generatePaginationUrl = (page: any) => {
    const isSearchPage = route.fullPath.startsWith("/search");
    const baseURL = isSearchPage ? "/search" : route.fullPath;

    isSearchPage ? (state.value.currentSearchPage = page) : (state.value.currentPage = page);

    const queryParams = generateQueryParams(page !== 1 && page !== undefined ? page : 1);
    let url = `${checkIfItsBrowserStepBack(baseURL)}?${queryParams}`;

    if (url.endsWith("?")) url = url.slice(0, -1);

    if (!isSearchPage && baseURL.includes("gad=1")) {
      url += url.includes("?") ? "&gad=1" : "?gad=1";
    }

    router.push(url);
  };

  const fetchPageData = async (page: number, fetchFunction: any, extraParams: any = {}) => {
    generatePaginationUrl(page);

    await nextTick();
    await fetchFunction({
      itemsPerPage: state.value.itemsPerPage,
      offset: (page - 1) * state.value.itemsPerPage,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
      ...extraParams,
    });

    const categories = combineAllFacets(
      categoryBCData.value?.facetResult?.fields || [],
      categoryBCData.value?.facetResult?.ranges || [],
    );

    state.value.facets = checkIsValidFacetAndSetUrl(categories) as any[];
  };

  // Fetch for search page (includes query)
  const fetchNewPageOnSearchPage = (page: number) => fetchPageData(page, search, { query: routeSlug as string });

  /**
   * Preloads the Largest Contentful Paint (LCP) image for performance optimization.
   * Watches category data changes and sets priority preload for first product image.
   *
   * @example
   * // In Vue component
   * const { usePreloadLCPImage } = useCategoryPage();
   * usePreloadLCPImage();
   *
   * @example
   * // In composable
   * usePreloadLCPImage();
   *
   * @see https://web.dev/lcp/ for LCP optimization details
   * @returns {void} Configures head with preload link when conditions met
   */
  const usePreloadLCPImage = (): void => {
    const stopWatch = watch(categoryBCData, (newValue) => {
      if (!newValue?.items?.length) return;

      const firstProduct = newValue.items[0];
      const imageUrl = firstProduct.imageSet?.original?.link?.href ?? firstProduct.imageSet?.thumbnail?.link?.href;

      if (!imageUrl) return;

      useHead({
        link: [
          {
            rel: "preload",
            as: "image",
            href: imageUrl,
            fetchpriority: "high",
          },
        ],
      });
    });

    onUnmounted(() => {
      stopWatch();
    });
  };

  const generateBreadcrumbs = () => {
    const breadcrumbs: BreadcrumbItemProps[] = [];

    let rootUrl = "/";

    Object.entries(route.params).forEach(([key, val], index) => {
      if (val) {
        rootUrl = rootUrl.concat(index === 0 ? "" : "/", val.toString());

        breadcrumbs.push({
          title: capitalizeFirstLetter(Array.isArray(val) ? val[0].toString() : val.toString()).replaceAll(/-/g, " "),
          href: `/category${rootUrl}`,
        });
      }
    });

    return breadcrumbs;
  };

  const generatePageTitle = () => {
    let rootUrl = "/";

    const titles = Object.entries(route.params).reduce(
      (acc, [key, val], index) => {
        if (!val) return acc;
        rootUrl = rootUrl.concat(index === 0 ? "" : "/", route.params[key].toString());
        const title = capitalizeWords(Array.isArray(val) ? val[0].toString() : val.toString()).replaceAll(/-/g, " ");
        return [...acc, { title }];
      },
      [] as { title: string }[],
    );

    return [...titles]
      .reverse()
      .map(({ title }) => title)
      .join(" | ");
  };

  const fetchNewPage = async (page?: any) => {
    generatePaginationUrl(page);

    state.value.currentPage = page;

    await nextTick();
    await fetchCategory({
      categoryID: state.value.currentCategoryBrId,
      itemsPerPage: state.value.itemsPerPage,
      offset: (state.value.currentPage - 1) * state.value.itemsPerPage,
      onlyTotal: false,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
    });

    const categories = combineAllFacets(
      categoryBCData.value?.facetResult?.fields || [],
      categoryBCData.value?.facetResult?.ranges || [],
    );

    state.value.facets = checkIsValidFacetAndSetUrl(categories) as any[];
  };

  const fetchItemsOnFirstMounting = async (categoryId: any, itemsPerPage = 10) => {
    state.value.currentPage = Number(route.query.p) || 1;

    await fetchCategory({
      categoryID: categoryId,
      itemsPerPage,
      offset: (state.value.currentPage - 1) * state.value.itemsPerPage,
      onlyTotal: false,
      filterValues: convertToBRData(),
      sortTerm: sortTerm.value,
    });

    const categories = combineAllFacets(
      categoryBCData.value?.facetResult?.fields || [],
      categoryBCData.value?.facetResult?.ranges || [],
    );

    state.value.facets = checkIsValidFacetAndSetUrl(categories) as any[];
  };

  const isCatLandingLayout = () => {
    const layout = bigCommerceCategory.value?.layout_file?.toLowerCase();
    return layout === "cat_landing" || layout === '["2", "cat_landing"]';
  };

  return {
    onSortBy,
    generateBreadcrumbs,
    isCatLandingLayout,
    fetchItemsOnFirstMounting,
    checkIsValidFacetAndSetUrl,
    fetchNewPageOnSearchPage,
    generatePageTitle,
    fetchNewPage,
    currentPagePath,
    categoryBC,
    ...toRefs(state.value),
    usePreloadLCPImage,
  };
};

export default useCategoryPage;
